var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "header" },
        [
          _c(
            "el-button-group",
            [
              _c(
                "el-button",
                {
                  class: { cur: _vm.temp.isReprint === 0 },
                  attrs: { type: "info", plain: "" },
                  on: {
                    click: function ($event) {
                      _vm.temp.isReprint = 0
                    },
                  },
                },
                [_vm._v("原创")]
              ),
              _c(
                "el-button",
                {
                  class: { cur: _vm.temp.isReprint === 1 },
                  attrs: { type: "info", plain: "" },
                  on: {
                    click: function ($event) {
                      _vm.temp.isReprint = 1
                    },
                  },
                },
                [_vm._v("转载")]
              ),
            ],
            1
          ),
          _c(
            "el-button",
            {
              staticStyle: { float: "right", "margin-right": "174px" },
              on: { click: _vm.preView },
            },
            [_vm._v("预览")]
          ),
          _c(
            "el-button",
            {
              staticStyle: { float: "right", "margin-right": "10px" },
              attrs: { loading: _vm.commitFlag, type: "primary" },
              on: { click: _vm.handleSave },
            },
            [_vm._v(_vm._s(_vm.$t("button.preservation")))]
          ),
          _c(
            "el-button",
            {
              staticStyle: { float: "right" },
              on: {
                click: function ($event) {
                  return _vm.$router.go(-1)
                },
              },
            },
            [_vm._v("取消")]
          ),
        ],
        1
      ),
      _c(
        "el-form",
        {
          ref: "dataForm",
          staticClass: "form-container bgFFF",
          attrs: {
            model: _vm.temp,
            inline: true,
            "label-width": "120px",
            "label-position": "right",
          },
        },
        [
          _c(
            "el-form-item",
            {
              staticStyle: { display: "block", width: "900px" },
              attrs: { label: "封面图片:" },
            },
            [
              _c(
                "el-radio-group",
                {
                  on: { change: _vm.coverChange },
                  model: {
                    value: _vm.temp.coverType,
                    callback: function ($$v) {
                      _vm.$set(_vm.temp, "coverType", $$v)
                    },
                    expression: "temp.coverType",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 0 } }, [_vm._v("单图模式")]),
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("三图模式")]),
                ],
                1
              ),
              _c(
                "el-upload",
                {
                  class: { hide: _vm.hideUploadEdit },
                  attrs: {
                    "on-remove": _vm.handleRemove,
                    "on-exceed": _vm.handleExceed,
                    "before-upload": _vm.beforeAvatarUpload2,
                    limit: _vm.coverLimit,
                    "http-request": _vm.fnUploadRequest2,
                    "file-list": _vm.fileList,
                    action: "",
                    "list-type": "picture-card",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "el-upload__tip",
                      attrs: { slot: "tip" },
                      slot: "tip",
                    },
                    [
                      _vm._v(
                        " 请上传图片，宽高比16：9，大小1M以内，支持格式.jpeg .jpg .png .gif .bmp "
                      ),
                    ]
                  ),
                  _c("i", { staticClass: "el-icon-plus" }),
                ]
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "标题:" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "350px" },
                        attrs: {
                          rows: 2,
                          type: "textarea",
                          placeholder: "请输入文章标题",
                          maxlength: "64",
                          "show-word-limit": "",
                        },
                        model: {
                          value: _vm.temp.title,
                          callback: function ($$v) {
                            _vm.$set(_vm.temp, "title", $$v)
                          },
                          expression: "temp.title",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "作者:" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "350px" },
                        attrs: {
                          autosize: "",
                          placeholder: "请输入文章作者",
                          maxlength: "20",
                          "show-word-limit": "",
                        },
                        model: {
                          value: _vm.temp.author,
                          callback: function ($$v) {
                            _vm.$set(_vm.temp, "author", $$v)
                          },
                          expression: "temp.author",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "区域:" } },
                    [
                      _c("el-cascader", {
                        attrs: {
                          options: _vm.cities,
                          props: {
                            expandTrigger: "hover",
                            multiple: true,
                            label: "areaName",
                            value: "areaId",
                            children: "childrenAreas",
                          },
                          "show-all-levels": false,
                          "collapse-tags": "",
                          clearable: "",
                          placeholder: "全国",
                        },
                        model: {
                          value: _vm.temp.cityNames,
                          callback: function ($$v) {
                            _vm.$set(_vm.temp, "cityNames", $$v)
                          },
                          expression: "temp.cityNames",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "发布渠道:" } },
                    [
                      _c(
                        "el-checkbox-group",
                        {
                          staticStyle: { width: "350px" },
                          model: {
                            value: _vm.temp.releaseType,
                            callback: function ($$v) {
                              _vm.$set(_vm.temp, "releaseType", $$v)
                            },
                            expression: "temp.releaseType",
                          },
                        },
                        [
                          _c("el-checkbox", { attrs: { label: 1 } }, [
                            _vm._v("APP"),
                          ]),
                          _c("el-checkbox", { attrs: { label: 2 } }, [
                            _vm._v("微信小程序"),
                          ]),
                          _c("el-checkbox", { attrs: { label: 4 } }, [
                            _vm._v("支付宝小程序"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "分享标题:" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "350px" },
                        attrs: {
                          placeholder: "请输入文章分享后的显示标题",
                          maxlength: "30",
                          "show-word-limit": "",
                        },
                        model: {
                          value: _vm.temp.shareTitle,
                          callback: function ($$v) {
                            _vm.$set(_vm.temp, "shareTitle", $$v)
                          },
                          expression: "temp.shareTitle",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "分享描述:" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "350px" },
                        attrs: {
                          placeholder: "请输入文章分享后的显示描述",
                          maxlength: "30",
                          "show-word-limit": "",
                        },
                        model: {
                          value: _vm.temp.shareDescribe,
                          callback: function ($$v) {
                            _vm.$set(_vm.temp, "shareDescribe", $$v)
                          },
                          expression: "temp.shareDescribe",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "分享缩略图:" } },
                    [
                      _c(
                        "el-upload",
                        {
                          class: { hide: _vm.hideUploadEdit2 },
                          attrs: {
                            "on-remove": _vm.handleRemoveForShare,
                            "on-exceed": _vm.handleExceedForShare,
                            "before-upload": _vm.beforeAvatarUpload,
                            limit: 1,
                            "http-request": _vm.fnUploadRequest3,
                            "file-list": _vm.shareFileList,
                            action: "",
                            "list-type": "picture-card",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "el-upload__tip",
                              attrs: { slot: "tip" },
                              slot: "tip",
                            },
                            [
                              _vm._v(
                                " 请上传图片，宽高比16：9，大小100kB以内，支持格式.jpg .png .jpeg "
                              ),
                            ]
                          ),
                          _c("i", { staticClass: "el-icon-plus" }),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "简介:" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "350px" },
                        attrs: {
                          rows: 2,
                          type: "textarea",
                          placeholder: "请输入文章简介",
                          maxlength: "120",
                          "show-word-limit": "",
                        },
                        model: {
                          value: _vm.temp.introduction,
                          callback: function ($$v) {
                            _vm.$set(_vm.temp, "introduction", $$v)
                          },
                          expression: "temp.introduction",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "是否置顶:" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          staticStyle: { width: "350px" },
                          model: {
                            value: _vm.temp.isStick,
                            callback: function ($$v) {
                              _vm.$set(_vm.temp, "isStick", $$v)
                            },
                            expression: "temp.isStick",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 1, value: "1" } }, [
                            _vm._v("是"),
                          ]),
                          _c("el-radio", { attrs: { label: 0, value: "0" } }, [
                            _vm._v("否"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "分类:" } },
                    [
                      _c("el-cascader", {
                        attrs: {
                          options: _vm.types,
                          props: {
                            expandTrigger: "hover",
                            value: "categoryId",
                            label: "nodeName",
                          },
                          placeholder: "请选择分类",
                        },
                        model: {
                          value: _vm.temp.categoryId,
                          callback: function ($$v) {
                            _vm.$set(_vm.temp, "categoryId", $$v)
                          },
                          expression: "temp.categoryId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "阅读数:" } },
                    [
                      [
                        _c(
                          "div",
                          {
                            staticClass: "label-slot",
                            attrs: { slot: "label" },
                            slot: "label",
                          },
                          [
                            _c(
                              "el-tooltip",
                              {
                                attrs: {
                                  effect: "dark",
                                  content:
                                    "说明：前台展示[阅读数]=基础值+用户实际操作值",
                                  placement: "top-start",
                                },
                              },
                              [
                                _c("img", {
                                  attrs: { src: require("./question.png") },
                                }),
                              ]
                            ),
                            _c("div", [_vm._v("阅读数:")]),
                            _vm.$route.name === "infoEdit"
                              ? _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      width: "100%",
                                      "line-height": "0.2em",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " (" + _vm._s(_vm.temp.readNumber) + ") "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c("el-input", {
                          staticStyle: { width: "120px" },
                          attrs: { placeholder: "基础值" },
                          model: {
                            value: _vm.temp.readBasics,
                            callback: function ($$v) {
                              _vm.$set(_vm.temp, "readBasics", $$v)
                            },
                            expression: "temp.readBasics",
                          },
                        }),
                        _c("span", [_vm._v(" + ")]),
                        _c("el-input", {
                          staticStyle: { width: "120px" },
                          attrs: { placeholder: "实际值", disabled: "" },
                          model: {
                            value: _vm.temp.userRead,
                            callback: function ($$v) {
                              _vm.$set(_vm.temp, "userRead", $$v)
                            },
                            expression: "temp.userRead",
                          },
                        }),
                        _c("span", [_vm._v(" × ")]),
                        _c("el-input", {
                          staticStyle: { width: "120px" },
                          attrs: { placeholder: "放大倍数" },
                          model: {
                            value: _vm.temp.readMultiple,
                            callback: function ($$v) {
                              _vm.$set(_vm.temp, "readMultiple", _vm._n($$v))
                            },
                            expression: "temp.readMultiple",
                          },
                        }),
                      ],
                    ],
                    2
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "点赞数:" } },
                    [
                      [
                        _c(
                          "div",
                          {
                            staticClass: "label-slot",
                            attrs: { slot: "label" },
                            slot: "label",
                          },
                          [
                            _c(
                              "el-tooltip",
                              {
                                attrs: {
                                  effect: "dark",
                                  content:
                                    "说明：前台展示[点赞数]=基础值+用户实际操作值",
                                  placement: "top-start",
                                },
                              },
                              [
                                _c("img", {
                                  attrs: { src: require("./question.png") },
                                }),
                              ]
                            ),
                            _c("div", [_vm._v("点赞数:")]),
                            _vm.$route.name === "infoEdit"
                              ? _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      width: "100%",
                                      "line-height": "0.2em",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " (" + _vm._s(_vm.temp.pointNumber) + ") "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c("el-input", {
                          staticStyle: { width: "120px" },
                          attrs: { placeholder: "基础值" },
                          model: {
                            value: _vm.temp.pointBasics,
                            callback: function ($$v) {
                              _vm.$set(_vm.temp, "pointBasics", $$v)
                            },
                            expression: "temp.pointBasics",
                          },
                        }),
                        _c("span", [_vm._v(" + ")]),
                        _c("el-input", {
                          staticStyle: { width: "120px" },
                          attrs: { placeholder: "实际值", disabled: "" },
                          model: {
                            value: _vm.temp.userPoint,
                            callback: function ($$v) {
                              _vm.$set(_vm.temp, "userPoint", $$v)
                            },
                            expression: "temp.userPoint",
                          },
                        }),
                        _c("span", [_vm._v(" × ")]),
                        _c("el-input", {
                          staticStyle: { width: "120px" },
                          attrs: { placeholder: "放大倍数" },
                          model: {
                            value: _vm.temp.pointMultiple,
                            callback: function ($$v) {
                              _vm.$set(_vm.temp, "pointMultiple", _vm._n($$v))
                            },
                            expression: "temp.pointMultiple",
                          },
                        }),
                      ],
                    ],
                    2
                  ),
                  _vm.temp.isReprint === 1
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "原文链接:" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "350px" },
                            attrs: {
                              placeholder: "请输入转发的原文地址",
                              maxlength: "100",
                            },
                            model: {
                              value: _vm.temp.jumpUrl,
                              callback: function ($$v) {
                                _vm.$set(_vm.temp, "jumpUrl", $$v)
                              },
                              expression: "temp.jumpUrl",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    { attrs: { label: "定时发布时间:" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "datetime",
                          placeholder: "非必填，按需计划任务",
                        },
                        model: {
                          value: _vm.temp.upperTime,
                          callback: function ($$v) {
                            _vm.$set(_vm.temp, "upperTime", $$v)
                          },
                          expression: "temp.upperTime",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "定时下架时间:" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "datetime",
                          placeholder: "非必填，按需计划任务",
                        },
                        model: {
                          value: _vm.temp.lowerTime,
                          callback: function ($$v) {
                            _vm.$set(_vm.temp, "lowerTime", $$v)
                          },
                          expression: "temp.lowerTime",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.temp.isReprint === 0
            ? _c("div", {
                staticStyle: {
                  "border-bottom": "1px solid #dcdfe6",
                  "margin-bottom": "20px",
                },
              })
            : _vm._e(),
          _vm.temp.isReprint === 0
            ? _c(
                "el-form-item",
                { staticStyle: { display: "block", width: "1200px" } },
                [
                  _c(
                    "el-upload",
                    {
                      staticStyle: { float: "left" },
                      attrs: {
                        "show-file-list": false,
                        "http-request": _vm.fnUploadRequest,
                        action: "",
                      },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: {
                            "margin-right": "20px",
                            "margin-left": "20px",
                          },
                          attrs: { type: "primary", size: "mini" },
                        },
                        [_vm._v("上传资源")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "span",
                    { staticStyle: { float: "left" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "930px" },
                        attrs: {
                          placeholder: "上传成功后资源的路径会显示在这里",
                        },
                        model: {
                          value: _vm.imageUrl,
                          callback: function ($$v) {
                            _vm.imageUrl = $$v
                          },
                          expression: "imageUrl",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.temp.isReprint === 0
            ? _c(
                "el-form-item",
                { staticStyle: { width: "100%" }, attrs: { label: "内容:" } },
                [
                  _c("tinymce-editor", {
                    ref: "editor",
                    model: {
                      value: _vm.temp.content,
                      callback: function ($$v) {
                        _vm.$set(_vm.temp, "content", $$v)
                      },
                      expression: "temp.content",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.previewVisible, title: "预览" },
          on: {
            "update:visible": function ($event) {
              _vm.previewVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "preview-container" },
            [
              _c("el-card", { staticClass: "preview-wrapper" }, [
                _c("div", { staticClass: "title" }, [
                  _vm._v(_vm._s(_vm.temp.title)),
                ]),
                _c("div", { staticClass: "tip" }, [
                  _c("span", { staticClass: "type" }, [_vm._v("资讯分类")]),
                  _c("span", [
                    _vm._v(
                      _vm._s(this.$moment(new Date()).format("YYYY-MM-DD"))
                    ),
                  ]),
                ]),
                _c("div", {
                  staticClass: "con",
                  staticStyle: { "line-height": "1.8em" },
                  domProps: { innerHTML: _vm._s(_vm.temp.content) },
                }),
              ]),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }